<template>
  <div>
    new
  </div>
</template>

<script>
export default {
  name: "New"
}
</script>

<style scoped>

</style>